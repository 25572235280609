<template>
    <div>
        <div class="btc-body container-fluid">
            <div class="btc-body-title">ブロックチェーンプラットフォーム紹介</div>
            <span>01</span>
            <div class="btc-body-desc">
                <div class="btc-body-desc-upper">
                    <div class="btc-body-desc-desc">
                        ブロックチェーンサービスNBaas(Nsservice's Blockchain as a Service)は、アライアンスチェーン方式を採用し、技術サービスを強化して、統一された標準仕様を定義することにより、どの企業もNBaasプラットフォーム上でさまざまな分野のブロックチェーンサービスを容易に構築できる。各領域のパートナーのためにもっと多い発展の空間を提供する。
                    </div>
                    <div class="btc-body-desc-upper-img">
                        <img src="@/assets/images/btc/img_qukuailian_1.png" alt="">
                    </div>
                </div>
                <div class="btc-body-desc-lower">
                    <div class="btc-body-desc-lower-title">
                        Blockchain as a Service
                    </div>
                    <div class="btc-body-desc-lower-info">
                        <div class="btc-body-desc-lower-sq"></div>
                        <div>NBaasはクラウドベースで、企業に向けて金融セキュリティレベルブロックチェーンインフラサービスを提供し、クラウド上のブロックチェーンサービスを通じて、安全で信頼性が高い柔軟なソリューションを提供する。ユーザーはクラウドプラットフォーム上で自社のITインフラとブロックチェーンサービスを迅速に構築することができる。</div>
                    </div>
                    <div class="btc-body-desc-lower-info">
                        <div class="btc-body-desc-lower-sq"></div>
                        <div>クライアント、ブロックチェーンブラウザ、運用モニタリング管理などの主要モジュールが含まれて、すべての機能が統合された可視化管理プラットフォームに統合されている。構成により、伝統的なブロックチェーンは技術者のみがコードで開発することができるという状況が変わり、操作が簡単になり、ユーザビリティを強め。サービスを提供し、クラウド上のブロックチェーンサービスを通じて、安全で信頼性が高い柔軟なソリューションを提供する。ユーザーはクラウドプラットフォーム上で自社のITインフラとブロックチェーンサービスを迅速に構築することができる。</div>
                    </div>
                </div>
            </div>
        </div>
         <div class="btc-body container-fluid" style="background-color:#FFFFFF">
            <div class="btc-body-title">特徴・メリット</div>
            <span>02</span>
            <div class="btc-body-header-1">特徴</div>
            <div style="display:flex;height:360px;">
                <div class="nav flex-column nav-pills nav-pills-container" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active nav-link-sp" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">スマートコントラク</a>
                    <a class="nav-link nav-link-sp" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">プライバシー保護</a>
                    <a class="nav-link nav-link-sp" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">マルチチェーンサポート</a>
                    <a class="nav-link nav-link-sp" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">デジタル署名</a>
                    <a class="nav-link nav-link-sp" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-settings" aria-selected="false">プライベートクラウドサービス</a>
                </div>
                <div class="tab-content tab-content-card" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div class="tab-pane-header">
                            <div class="tab-pane-title">特徴1</div>
                            <div >
                                <img src="@/assets/images/btc/tezheng_num_01.png" alt="">
                            </div>
                        </div>
                        <div class="tab-pane-desc">
                            スマートコントラク開発は、ブロックチェーンアプリケーションの主な機能で、自動トリガー、セキュリティ分離、サービス定義、およびデジタルプロトコルを実装するスマートコントラクを中心としたブロックチェーンのビジネス機能はすべて、したがって、スマートコントラクトは、ブロックチェーンアプリケーション開発プロセスの中で最も重要な部分である。
                        </div>
                        <div class="tab-pane-image">
                            <img src="@/assets/images/btc/img_qukuailian2.png" alt="">
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                         <div class="tab-pane-header">
                            <div class="tab-pane-title">特徴2</div>
                            <div >
                                <img src="@/assets/images/btc/tezheng_num_02.png" alt="">
                            </div>
                        </div>
                        <div class="tab-pane-desc">
                        ブロックチェーンプラットフォームは、デジタル証明書ベースのアイデンティティ管理、マルチチェーンアイソレーション、情報暗号化、スマート契約管理を使用して個人情報を保護する。
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <div class="tab-pane-header">
                            <div class="tab-pane-title">特徴3</div>
                            <div >
                                <img src="@/assets/images/btc/tezheng_num_03.png" alt="">
                            </div>
                        </div>
                        <div class="tab-pane-desc">
                            論理ブロックチェーンは、特定の組織や特定のノードを集約するプライベートブロックチェーンシステムで、異なる組織の間で異なる論理ブロックチェーンを確立できる。チェーン間のデータ分離、スマートコントラクトを異なる論理ブロックチェーンに配置することができる。
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                         <div class="tab-pane-header">
                            <div class="tab-pane-title">特徴4</div>
                            <div >
                                <img src="@/assets/images/btc/tezheng_num_04.png" alt="">
                            </div>
                        </div>
                        <div class="tab-pane-desc">
                            デジタル署名は、非対称暗号化技術で、データの改ざんが困難、身分の所有者は送信された情報を署名し、暗号化するために保持された秘密鍵を使用する。情報受信者は、公開鍵を使用して情報を解読し、校正して情報伝達プロセスが変更されていないことを保証する。デジタル署名は、ネットワークの世界の中で、その身元を証明し、情報の流れを確実にするための重要なツールです。
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                         <div class="tab-pane-header">
                            <div class="tab-pane-title">特徴5</div>
                            <div >
                                <img src="@/assets/images/btc/tezheng_num_05.png" alt="">
                            </div>
                        </div>
                        <div class="tab-pane-desc">
                            金融、電気通信、政府、エネルギー、教育、輸送およびその他の産業では、ユーザーのコアビジネスは独立して制御可能である必要がある。NBaasは、このニーズをより良く満たすため、NBaasは私有化配置の方法をサポートしている。NBaasの導入方法は穏健なプラットフォーム上に構築されており、ユーザーはNBaasクラウドプラットフォーム全体を制御できる。
                        </div>
                    </div>
                </div>
            </div>
            <div class="btc-body-header-2">メリット</div>
            <div class="btc-body-lower" style="padding-bottom:60px;">
                <div class="row">
                    <div @click="selectedSqrHandler(index)" style="cursor:pointer;" class="col p-0" v-for="(item, index) in btcMiddleData" :key="index">
                        <img style="margin-bottom:20px" :src="item.imageUrl" alt="">
                        <p :style="{color:item.index === selectedSqr?'#0262FF':''}">{{item.value}}</p>
                    </div>
                </div>
                <div class="btc-body-lower-sqr">
                    <div >
                        <div style="width:0;height:0;position:relative;top:33px;">
                            <img src="@/assets/images/btc/yinhao-2.png" alt="">
                        </div>
                        <div id="btc-body-lower-text" class="btc-body-sqr-text">
                            {{btcMiddleData[selectedSqr].text}}
                        </div>
                    </div>
                    <div id="btc-body-lower-img" class="btc-body-lower-sqr-image">
                        <img :src="btcMiddleData[selectedSqr].descImageUrl" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="btc-body container-fluid btc-example">
            <div style="color:#FFFFFF" class="btc-body-title">応用事例</div>
            <span style="color:#FFFFFF">03</span>
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">A</li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1">B</li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2">C</li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3">D</li>
                <li data-target="#carouselExampleIndicators" data-slide-to="4">E</li>
                <li data-target="#carouselExampleIndicators" data-slide-to="5">F</li>
                <li data-target="#carouselExampleIndicators" data-slide-to="6">G</li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="carousel-card">
                        <div class="carousel-title">ケースA：国際送金</div>
                        <div class="carousel-body">
                            <div style="width:calc(100vw/1920*410);">
                                <div style="min-height:120px">
                                    <img class="btc-1-img" style="width:calc(100vw/1920*410)" src="@/assets/images/btc/img_anli_a1.png" alt="">
                                </div>
                                <div class="carousel-body-desc">
                                    1、従来の国際送金「遅い、高い、分かりにくい」という不満あり
                                </div>
                            </div>
                            <div style="width:calc(100vw/1920*35)">
                                <img class="btc-1-arrow" style="width:calc(100vw/1920*35);margin-top:40px;" src="@/assets/images/btc/img_anli_a3.png" alt="">
                            </div>
                            <div style="width:calc(100vw/1920*410);">
                                <div style="min-height:120px">
                                    <img class="btc-1-img" style="width:calc(100vw/1920*410);margin-top:40px;" src="@/assets/images/btc/img_anli_a2.png" alt="">
                                </div>
                                <div class="carousel-body-desc">
                                    2、リップル・プロジェクト–DLTを使って、銀行と銀行が直接つながり、分散型台帳で情報を共有しつつ、リアルタイムで送金を行う
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-card">
                        <div class="carousel-title">ケースB：保険申込書類の確認作業</div>
                        <div class="carousel-body">
                            <div>
                                <div style="width:150%" class="carousel-body-desc">
                                ・保険A会社が 実証実験 ・DLT上で情報を共有 <br>
                                ・期待される効果: <br>
                                1.保険証券の発行期間の短縮化 <br>
                                2.高度なセキュリティの確保 -情報漏えい、紛失リスク <br>
                                3.安価なシステム構築
                                </div>
                            </div>
                        <div class="carousel-body-img" style="width:calc(100vw/1920*580);">
                            <div class="carousel-body-img" style="margin-left:40px;">
                                <img class="carousel-body-img" style="width:calc(100vw/1920*580);margin-left:40px;" src="@/assets/images/btc/img_anli_b.png" alt="">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-card">
                        <div class="carousel-title">ケースC：農産物トレーサビリティ確保</div>
                        <div class="carousel-body">
                            <div>
                                <div style="width:150%;line-height:36px" class="carousel-body-desc">
                                1、課題点：商品偽造、製品トレーサビリティ <br>
                                2、経路：豚肉の流通経路、有機野菜の流通経路
                                </div>
                            </div>
                        <div class="carousel-body-img" style="width:calc(100vw/1920*580);">
                            <div class="carousel-body-img" style="margin-left:40px;">
                                <img class="carousel-body-img" style="width:calc(100vw/1920*580);" src="@/assets/images/btc/img_anli_c.png" alt="">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-card">
                        <div class="carousel-title">ケースD：スマートコントラクト・ 電子契約など</div>
                        <div class="carousel-body">
                            <div>
                                <div style="width:150%;line-height:calc(100vw/1920*36)" class="carousel-body-desc">
                                1、ビジネス上問題点：証明書が遅く、流れが長く、偽チケット <br>
                                2、ブロックチェーンを用いてサプライチェーンを強化
                                </div>
                            </div>
                        <div class="carousel-body-img" style="width:calc(100vw/1920*479);">
                            <div class="carousel-body-img" style="margin-left:40px;">
                                <img class="carousel-body-img" style="width:calc(100vw/1920*479);" src="@/assets/images/btc/img_anli_d.png" alt="">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-card">
                        <div class="carousel-title">ケースE：電力取引の 自動化・効率化</div>
                        <div class="carousel-body">
                            <div>
                                <div style="width:calc(100vw/1920*410);line-height:calc(100vw/1920*36)" class="carousel-body-desc">
                               電力会社から分散型電源(自治体などが保有する非常用電源や一般家庭の太陽光発電など)への発電要請、対価支払いといった電力取引の履歴管理について、スマートコントラクトを活用して自動で処理し、透明性ある電力シェアリングエコノミーを形成。
                                </div>
                            </div>
                        <div class="carousel-body-img" style="width:calc(100vw/1920*451);">
                            <div class="carousel-body-img" style="margin-left:40px;">
                                <img class="carousel-body-img" style="width:calc(100vw/1920*451);" src="@/assets/images/btc/img_anli_e.png" alt="">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-card">
                        <div class="carousel-title">ケースF：自動車のトレーサビ リテ</div>
                        <div class="carousel-body">
                            <div>
                                <div style="width:calc(100vw/1920*410);line-height:calc(100vw/1920*36)" class="carousel-body-desc">
                                Connected Carに関する多様な機器・サービスのうち「誰(どの機器)が、いつ、何を行ったか」をブロックチェーン上に記録することで、出荷から車検、中古車販売など、自動車のライフサイクルにおける正確なトレーサビリティを関係者間で確保。                                </div>
                            </div>
                        <div class="carousel-body-img" style="width:calc(100vw/1920*451);">
                            <div class="carousel-body-img" style="margin-left:40px;">
                                <img class="carousel-body-img" style="width:calc(100vw/1920*451);" src="@/assets/images/btc/img_anli_f.png" alt="">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-card">
                        <div class="carousel-title">ケースG：医療データの真正性確認</div>
                        <div class="carousel-body">
                            <div>
                                <div style="width:calc(100vw/1920*410);line-height:calc(100vw/1920*36)" class="carousel-body-desc">
                                在宅医療に携わる関係者(医師、看護師、救急隊員など)が、ブロックチェーン上で管理されている署名済みの在宅医療データの「ハッシュ」(アクセス可能な範囲でグルーピングされたもの)を検証することで、アクセスコントロールを効かせつつ、別のデータベースに格納されている患者のデータの真正性を確認。
                                 </div>
                            </div>
                        <div class="carousel-body-img" style="width:calc(100vw/1920*451);">
                            <div class="carousel-body-img" style="margin-left:40px;">
                                <img class="carousel-body-img" style="width:calc(100vw/1920*451);" src="@/assets/images/btc/img_anli_g.png" alt="">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            sqrText:'',
            selectedSqr:0,
            btcMiddleData:[
                {
                    index:0,
                    imageUrl:require('@/assets/images/btc/icon_qukuailian_youdian1.png'),
                    descImageUrl:require('@/assets/images/btc/img_qukuailian_youdian1.png'),
                    value:'サービスとしてのブロックチェーン',
                    text:'権限をシステム権限とビジネス権限に分割し、ページレベルに細分化し、権限を役割にバインドし、役割をユーザーにバインドする。ユーザーは、権限の分離を達成するために1つ以上の役割を持つことができる。サービス者のみを保持し、オペレーションとメンテナンスはシステム保守権限を保持する。サービスプロバイダーのプライバシーを保護しながらシステムの可用性を保証する。',
                },{
                    index:1,
                    imageUrl:require('@/assets/images/btc/icon_qukuailian_youdian2.png'),
                    descImageUrl:require('@/assets/images/btc/img_qukuailian_youdian2.png'),
                    value:'データ独立性保持',
                    text:'NBaasは、データをチャネル単位で分離して保存し、各チャネルは独立しており、独自のコンセンサスサービスノードを持ち、独立してデータの計算と記録を行う。自分のデータと契約を維持する。チャンネルのデータはチャンネルに参加する組織にのみ表示され、チャンネル間でデータが分離され、参加者のデータプライバシーを確保する。',
                },{
                    index:2,
                    imageUrl:require('@/assets/images/btc/icon_qukuailian_youdian3.png'),
                    descImageUrl:require('@/assets/images/btc/img_qukuailian_youdian3.png'),
                    value:'データ分離機密保持',
                    text:'システム内のチャネル、組織、ノードなどの物理的な要素を構成および管理するビジュアル構成ページが装備されてシステム参加者とシステムの動作状況を確認すると便利で、同時に、ブロックチェーン上のデータをリアルタイムで表示し、チェーン上のトランザクションステータスを理解できるブロックチェーンブラウザが装備される。'
                },{
                    index:3,
                    imageUrl:require('@/assets/images/btc/icon_qukuailian_youdian4.png'),
                    descImageUrl:require('@/assets/images/btc/img_qukuailian_youdian4.png'),
                    value:'データセキュリティ',
                    text:'マルチチャネルアーキテクチャにより、データのプライバシーが確保され、データチャネル内での共有が可能になり。ユーザーIDブロックチェーン上の各ユーザーは、1組独自の公開鍵と秘密鍵を持ち、公開鍵と秘密鍵は非対称暗号化を使用してデータ伝送のセキュリティを保証する。これにより、情報転送プロセスを改ざんできないようにすることができる。'
                },{
                    index:4,
                    imageUrl:require('@/assets/images/btc/icon_qukuailian_youdian5.png'),
                    descImageUrl:require('@/assets/images/btc/img_qukuailian_youdian5.png'),
                    value:'権限の設定',
                    text:'権限をシステム権限とビジネス権限に分割し、ページレベルに細分化し、権限を役割にバインドし、役割をユーザーにバインドする。ユーザーは、権限の分離を達成するために1つ以上の役割を持つことができる。サービス者のみを保持し、オペレーションとメンテナンスはシステム保守権限を保持する。サービスプロバイダーのプライバシーを保護しながらシステムの可用性を保証する。'
                },{
                    index:5,
                    imageUrl:require('@/assets/images/btc/icon_qukuailian_youdian6.png'),
                    descImageUrl:require('@/assets/images/btc/img_qukuailian_youdian6.png'),
                    value:'ハイブリッドクラウド',
                    text:'パブリッククラウドとプライベートクラウドの結びつき、その中でパブリッククラウドは、参加者が独自のブロックチェーンシステムを迅速に構築することを保証する。プライベートクラウドは、個々のユーザーのビジネスニーズに合わせてカスタマイズされている。カスタマイズされた特別なシステムをユーザーに提供する。'
                },{
                    index:6,
                    imageUrl:require('@/assets/images/btc/icon_qukuailian_youdian7.png'),
                    descImageUrl:require('@/assets/images/btc/img_qukuailian_youdian7.png'),
                    value:'標準化された契約テンプレート',
                    text:'業界固有の特定のビジネスシナリオについては、標準化された契約を提供し、メインプロセスをカバーし、ユーザーはシステムをドッキングしてより迅速で便利で、さらに、このシステムはユーザー定義の契約開発もサポートしている。'
                }
            ]
        }
    },
    methods: {
        selectedSqrHandler(index){
            if(this.selectedSqr === index){
                return false
            }
            document.getElementById('btc-body-lower-text').style.opacity = "0"
            document.getElementById('btc-body-lower-text').style.transition = "all .3s"
            document.getElementById('btc-body-lower-img').style.opacity = "0"
            document.getElementById('btc-body-lower-img').style.webkitTransform = "translate(200px,0)"
            document.getElementById('btc-body-lower-img').style.transition = "all 0s"
            setTimeout(() => {
                this.selectedSqr = index
                document.getElementById('btc-body-lower-text').style.opacity = "1"
                document.getElementById('btc-body-lower-text').style.transition = "all .3s"
                document.getElementById('btc-body-lower-img').style.opacity = "1"
                document.getElementById('btc-body-lower-img').style.webkitTransform = "translate(0)"
                document.getElementById('btc-body-lower-img').style.transition = "all .3s"
            },300)
        }
    },
}
</script>

<style>
    @media screen and (min-width:768px) and (max-width:1920px) {
        .btc-body-desc{
            /* width: calc(100vw/1920*1200); */
            /* height: calc(100vw/1920*520); */
            background-color: #FFFFFF;
        }
        .btc-body-background{
            width: calc(100vw/1920*762);
            height: calc(100vw/1920*493);
            background-color: #0262FF;
            position: absolute;
            z-index: 0;
            left: 0;
        }
        .btc-body{
            background-color: #F5F5F9;
            padding: 0 calc(100vw/1920*360);
            padding-bottom: 40px;
            z-index: 1;
        }
        .btc-body .container-fluid{
            z-index: 1;
        }
        .btc-body-title{
            font-size:calc(100vw/1920*24);
            padding-top: 45px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:600;
            color:rgba(2,98,255,1);
            line-height:33px;
        }
        .btc-body>span{
            width:89px;
            height:89px;
            font-size:80px;
            font-family:Arial-BoldMT,Arial;
            font-weight:normal;
            color:rgba(2,98,255,1);
            line-height:92px;
            position: relative;
            /* right: calc(100vw/1920*200); */
            bottom: 60px;
            opacity: 0.1;
        }
        .btc-body-desc-upper{
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: calc(100vw/1920*250);
        }
        .btc-body-desc-desc{
            width: calc(100vw/1920*716);
            padding: calc(100vw/1920*39) calc(100vw/1920*46);
            font-size:calc(100vw/1920*16);
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:calc(100vw/1920*32);
            text-align: left;
        }
        .btc-body-desc-upper-img{
            width: calc(100vw/1920*484);
            height: calc(100vw/1920*250);
        }
        .btc-body-desc-upper-img img{
            width: calc(100vw/1920*484);
            height: calc(100vw/1920*250);
        }
        .btc-body-desc-lower{
            padding: 0 30px 45px 30px;
        }
        .btc-body-desc-lower-title{
            text-align: left;
            margin-bottom: 20px;

        }
        .btc-body-desc-lower-title div{
            font-size:calc(100vw/1920*18);;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(51,51,51,1);
            line-height:calc(100vw/1920*25);
        }
        .btc-body-desc-lower-info{
            display: flex;
            margin-bottom: 17px;
        }
        .btc-body-desc-lower-info div{
            font-size:calc(100vw/1920*14);
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(102,102,102,1);
            line-height:calc(100vw/1920*32);
            text-align: left;
        }
        .btc-body-desc-lower-sq{
            position: relative;
            top: 10px;
            min-width:22px;
            height:22px;
            margin-right: 22px;
            background:rgba(254,143,3,1);
        }
        .tab-pane-title{
            font-size:18px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(51,51,51,1);
            line-height:25px;
            text-align: left;
            margin-bottom: 15px;
        }
        .tab-pane-desc{
            font-size:calc(100vw/1920*16);
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(102,102,102,1);
            line-height:calc(100vw/1920*32);
            text-align: left;
            margin-bottom: 40px;
        }
        .tab-pane-image img{
            width: 500px
        }
        .tab-pane-header{
            height:40px;
            display:flex;
            justify-content:space-between;
        }
        .tab-content-card{
            border-left: 4px solid #0262FF;
            width:calc(100vw/1920*918);
            padding:15px 24px;
            margin-bottom:36px;
            box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        }
        .nav-link-sp{
            border-radius: unset !important;
            text-align: left;
            height: 60px;
            font-size:calc(100vw/1920*16);
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            line-height:44px;
        }
        .nav-pills-container{
            width:calc(100vw/1920*360);
            margin-right:20px;
            margin-bottom: 36px;
            border-left: 2px solid #D9E0EC;
        }
        .btc-body-header-1{
            font-size:calc(100vw/1920*24);
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:700;
            color:rgba(51,51,51,1);
            line-height:calc(100vw/1920*33);
            text-align: left;
            margin-bottom: 20px;
        }
        .btc-body-header-2{
            font-size:calc(100vw/1920*24);
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:700;
            color:rgba(51,51,51,1);
            line-height:calc(100vw/1920*33);
            text-align: left;
            margin-top: 90px;
            margin-bottom: 20px;
        }
        .btc-body-lower p{
            font-size:calc(100vw/1920*16);
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(102,102,102,1);
            line-height:calc(100vw/1920*22);
        }
        .btc-body-lower-sqr{
            margin-top: 20px;
            width:100%;
            background:rgba(2,98,255,1);
            display:flex;
            padding: 0 30px;
            padding-bottom: 20px;
        }
        .btc-body-sqr-text{
            width: calc(100vw/1920*680);
            text-align: left;
            font-size:calc(100vw/1920*16);
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(255,255,255,1);
            line-height:calc(100vw/1920*32);
            margin-top: 75px;
            margin-right: 30px;
        }
        .btc-body-lower-sqr-image{
            width: calc(100vw/1920*416);
            height: calc(100vw/1920*300);
            top: calc(100vw/1920*75);
            position: relative;
            background: #FFF;
            box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
            border-radius:8px;
        }
        .btc-body-lower-sqr-image img{
            width: calc(100vw/1920*416);
            height: calc(100vw/1920*300);
        }
        .btc-example{
            height: 810px;
            background-image: url('../../assets/images/btc/bg_qukuailian.png')
        }
        .carousel-card{
            width:100%;
            height:500px;
            background:#FFF;
        }
        .carousel-title{
            font-size:18px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(2,98,255,1);
            line-height:25px;
            text-align: left;
            padding: calc(100vw/1920*40) calc(100vw/1920*80);
        }
        .carousel-body{
            padding: 80px 90px;
            display: flex;
            justify-content:space-around;
        }
        .carousel-indicators{
            position: absolute;
            bottom: -70px;
        }

        .carousel-indicators li{
            text-indent:unset;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            background-color: unset;
            border: 1px solid #FFF;
            color: #FFF;
            margin-right: 10px;
        }
        .carousel-body-desc{
            /* margin-top: 30px; */
            font-size:calc(100vw/1920*16);
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:calc(100vw/1920*32);
            text-align: left;
            
        }
        .carousel-indicators .active{
            background-color: #FFF;
            color: #0262FF;
        }
        .carousel-control-prev-icon:hover{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_r1.png');
        }
        .carousel-control-prev-icon{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_r2.png');
            margin-right:40px;
        }
        .carousel-control-next-icon{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_l2.png');
            margin-left:40px;
        }
        .carousel-control-next-icon:hover{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_l1.png');
        }
        .carousel-control-next, .carousel-control-prev{
            opacity: 1;
        }
    }
    @media screen and (max-width:768px){
        .btc-body-desc{
            /* width: calc(100vw/1920*1200); */
            /* height: calc(100vw/1920*520); */
            background-color: #FFFFFF;
        }
        .btc-body-background{
            width: calc(100vw/1920*762);
            height: calc(100vw/1920*493);
            background-color: #0262FF;
            position: absolute;
            z-index: 0;
            left: 0;
        }
        .btc-body{
            background-color: #F5F5F9;
            padding: 20px;
            padding-bottom: 40px;
            z-index: 1;
        }
        .btc-body .container-fluid{
            z-index: 1;
        }
        .btc-body-title{
            font-size:12px;
            /* padding-top: 45px; */
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:600;
            color:rgba(2,98,255,1);
            line-height:33px;
        }
        .btc-body>span{
            width:89px;
            height:89px;
            font-family:Arial-BoldMT,Arial;
            font-weight:normal;
            color:rgba(2,98,255,1);
            line-height:92px;
            position: relative;
            /* right: calc(100vw/1920*200); */
            bottom: 60px;
            opacity: 0.1;
            font-size: 40px;
        }
        .btc-body-desc-upper{
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding:20px;
            /* height: calc(100vw/1920*250); */
        }
        .btc-body-desc-desc{
            /* width: 200px; */
            padding: 10px;
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:14px;
            text-align: left;
        }
        .btc-body-desc-upper-img{
            display:none;
        }
        .btc-body-desc-upper-img img{
            width: 150px;
        }
        .btc-body-desc-lower{
            padding: 0 30px 45px 30px;
        }
        .btc-body-desc-lower-title{
            text-align: left;
            margin-bottom: 20px;

        }
        .btc-body-desc-lower-title div{
            font-size:calc(100vw/1920*18);;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(51,51,51,1);
            line-height:calc(100vw/1920*25);
        }
        .btc-body-desc-lower-info{
            display: flex;
            margin-bottom: 17px;
        }
        .btc-body-desc-lower-info div{
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(102,102,102,1);
            line-height:14px;
            text-align: left;
        }
        .btc-body-desc-lower-sq{
            position: relative;
            top: 10px;
            min-width:22px;
            height:22px;
            margin-right: 22px;
            background:rgba(254,143,3,1);
        }
        .tab-pane-title{
            font-size:18px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(51,51,51,1);
            line-height:25px;
            text-align: left;
            margin-bottom: 15px;
        }
        .tab-pane-desc{
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(102,102,102,1);
            line-height:14px;
            text-align: left;
            margin-top: 40px;
        }
        .tab-pane-image img{
            width: 100%;
        }
        .tab-pane-header{
            height:40px;
            display:flex;
            justify-content:space-between;
        }
        .tab-content-card{
            border-left: 4px solid #0262FF;
            width:200px;
            padding:6px 12px;
            margin-bottom:36px;
            box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        }
        .nav-link-sp{
            border-radius: unset !important;
            text-align: left;
            height: 60px;
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            line-height:20px;
        }
        .nav-pills-container{
            width:150px;
            margin-bottom: 36px;
            border-left: 2px solid #D9E0EC;
        }
        .btc-body-header-1{
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:700;
            color:rgba(51,51,51,1);
            line-height:calc(100vw/1920*33);
            text-align: left;
            margin-bottom: 20px;
        }
        .btc-body-header-2{
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:700;
            color:rgba(51,51,51,1);
            line-height:calc(100vw/1920*33);
            text-align: left;
            margin-top: 90px;
            margin-bottom: 20px;
        }
        .btc-body-lower p{
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(102,102,102,1);
            line-height:14px;
        }

        .btc-body-lower-sqr{
            margin-top: 20px;
            width:100%;
            background:rgba(2,98,255,1);
            display:flex;
            padding: 0 30px;
            padding-bottom: 20px;
        }
        .btc-body-sqr-text{
            /* width: 180px; */
            text-align: left;
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(255,255,255,1);
            /* line-height:14px; */
            margin-top: 65px;
            /* margin-right: 30px; */
        }
        .btc-body-lower-sqr-image{
           display: none;
        }
        .btc-body-lower-sqr-image img{
            width: 100px;
            height: 80px;
        }
        .btc-example{
            height: 810px;
            background-image: url('../../assets/images/btc/bg_qukuailian.png')
        }
        .carousel-card{
            width:100%;
            height:500px;
            background:#FFF;
        }
        .carousel-title{
            font-size:18px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(2,98,255,1);
            line-height:25px;
            text-align: left;
            padding: calc(100vw/1920*40) calc(100vw/1920*80);
        }
        .carousel-body{
            padding: 20px 30px;
            /* display: flex; */
            justify-content:space-around;
        }
        .carousel-body-img{
            margin-top: 40px;
            width:100% !important;
            margin-left: unset !important;
        }
        .carousel-body div{
            width:unset !important;
        }
        .btc-1-img{
            width:100% !important;
        }
        .carousel-indicators{
            position: absolute;
            bottom: -70px;
            margin: 0;
        }

        .carousel-indicators li{
            text-indent:unset;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            background-color: unset;
            border: 1px solid #FFF;
            color: #FFF;
            margin-right: 10px;
        }
        .carousel-body-desc{
            /* margin-top: 30px; */
            font-size:12px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:14px !important;
            text-align: left;
            
        }
        .btc-1-arrow{
            width: 20% !important;

            transform: rotate(90deg);
        }
        .carousel-indicators .active{
            background-color: #FFF;
            color: #0262FF;
        }
        .carousel-control-prev-icon:hover{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_r1.png');
        }
        .carousel-control-prev-icon{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_r2.png');
            margin-right:40px;
        }
        .carousel-control-next-icon{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_l2.png');
            margin-left:40px;
        }
        .carousel-control-next-icon:hover{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_l1.png');
        }
        .carousel-control-next, .carousel-control-prev{
            opacity: 1;
        }
    }
    @media screen and (min-width:1920px){
        .btc-body-desc{
            background-color: #FFFFFF;
        }
        .btc-body-background{
            width: 762px;
            height: 493px;
            background-color: #0262FF;
            position: absolute;
            z-index: 0;
            left: 0;
        }
        .btc-body{
            background-color: #F5F5F9;
            padding: 0 360px;
            padding-bottom: 40px;
            z-index: 1;
        }
        .btc-body .container-fluid{
            z-index: 1;
        }
        .btc-body-title{
            font-size:24px;
            padding-top: 45px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:600;
            color:rgba(2,98,255,1);
            line-height:33px;
        }
        .btc-body>span{
            width:89px;
            height:89px;
            font-size:80px;
            font-family:Arial-BoldMT,Arial;
            font-weight:normal;
            color:rgba(2,98,255,1);
            line-height:92px;
            position: relative;
            /* right: calc(100vw/1920*200); */
            bottom: 60px;
            opacity: 0.1;
        }
        .btc-body-desc-upper{
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 250px;
        }
        .btc-body-desc-desc{
            width: 716px;
            padding: 39px 46px;
            font-size:16px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:32px;
            text-align: left;
        }
        .btc-body-desc-upper-img{
            width: 484px;
            height: 250px;
        }
        .btc-body-desc-upper-img img{
            width: 484px;
            height: 250px;
        }
        .btc-body-desc-lower{
            padding: 0 30px 45px 30px;
        }
        .btc-body-desc-lower-title{
            text-align: left;
            margin-bottom: 20px;

        }
        .btc-body-desc-lower-title div{
            font-size:18px;;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(51,51,51,1);
            line-height:25px;
        }
        .btc-body-desc-lower-info{
            display: flex;
            margin-bottom: 17px;
        }
        .btc-body-desc-lower-info div{
            font-size:14px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(102,102,102,1);
            line-height:32px;
            text-align: left;
        }
        .btc-body-desc-lower-sq{
            position: relative;
            top: 10px;
            min-width:22px;
            height:22px;
            margin-right: 22px;
            background:rgba(254,143,3,1);
        }
        .tab-pane-title{
            font-size:18px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(51,51,51,1);
            line-height:25px;
            text-align: left;
            margin-bottom: 15px;
        }
        .tab-pane-desc{
            font-size:16px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(102,102,102,1);
            line-height:32px;
            text-align: left;
            margin-bottom: 40px;
        }
        .tab-pane-image img{
            width: 500px
        }
        .tab-pane-header{
            height:40px;
            display:flex;
            justify-content:space-between;
        }
        .tab-content-card{
            border-left: 4px solid #0262FF;
            width:918px;
            padding:15px 24px;
            margin-bottom:36px;
            box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        }
        .nav-link-sp{
            border-radius: unset !important;
            text-align: left;
            height: 60px;
            font-size:16px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            line-height:44px;
        }
        .nav-pills-container{
            width:360px;
            margin-right:20px;
            margin-bottom: 36px;
            border-left: 2px solid #D9E0EC;
        }
        .btc-body-header-1{
            font-size:24px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:700;
            color:rgba(51,51,51,1);
            line-height:33px;
            text-align: left;
            margin-bottom: 20px;
        }
        .btc-body-header-2{
            font-size:24px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:700;
            color:rgba(51,51,51,1);
            line-height:33px;
            text-align: left;
            margin-top: 90px;
            margin-bottom: 20px;
        }
        .btc-body-lower p{
            font-size:16px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(102,102,102,1);
            line-height:22px;
        }
        .btc-body-lower-sqr{
            margin-top: 20px;
            width:100%;
            background:rgba(2,98,255,1);
            display:flex;
            padding: 0 30px;
            padding-bottom: 20px;
        }
        .btc-body-sqr-text{
            width: 680px;
            text-align: left;
            font-size:16px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(255,255,255,1);
            line-height:32px;
            margin-top: 75px;
            margin-right: 30px;
        }
        .btc-body-lower-sqr-image{
            width: 416px;
            height: 300px;
            top: 75px;
            position: relative;
            background: #FFF;
            box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
            border-radius:8px;
        }
        .btc-body-lower-sqr-image img{
            width: 416px;
            height: 300px;
        }
        .btc-example{
            height: 810px;
            background-image: url('../../assets/images/btc/bg_qukuailian.png')
        }
        .carousel-card{
            width:100%;
            height:500px;
            background:#FFF;
        }
        .carousel-title{
            font-size:18px;
            font-family:Noto Serif JP,YuGothic,serif;;
            font-weight:500;
            color:rgba(2,98,255,1);
            line-height:25px;
            text-align: left;
            padding: 40px 80px;
        }
        .carousel-body{
            padding: 80px 90px;
            display: flex;
            justify-content:space-around;
        }
        .carousel-indicators{
            position: absolute;
            bottom: -70px;
        }

        .carousel-indicators li{
            text-indent:unset;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            background-color: unset;
            border: 1px solid #FFF;
            color: #FFF;
            margin-right: 10px;
        }
        .carousel-body-desc{
            /* margin-top: 30px; */
            font-size:16px;
            font-family:Noto Serif JP,YuGothic,serif;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:32px;
            text-align: left;
            
        }
        .carousel-indicators .active{
            background-color: #FFF;
            color: #0262FF;
        }
        .carousel-control-prev-icon:hover{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_r1.png');
        }
        .carousel-control-prev-icon{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_r2.png');
            margin-right:40px;
        }
        .carousel-control-next-icon{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_l2.png');
            margin-left:40px;
        }
        .carousel-control-next-icon:hover{
            background-image:url('../../assets/images/btc/icon_qukuailian_jiantou_l1.png');
        }
        .carousel-control-next, .carousel-control-prev{
            opacity: 1;
        }
    }
</style>